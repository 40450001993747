import type { AppProps } from 'next/app';
import Head from 'next/head';

import ErrorBoundary from '@/components/casino/ErrorBoundary';

import 'react-toastify/dist/ReactToastify.css';
import '@/styles/globals.scss';
import '@/styles/tailwind.base.scss';
import '@/styles/tailwind.components.scss';
import '@/styles/tailwind.utility.scss';
import 'overlayscrollbars/overlayscrollbars.css';

type MyAppProps = AppProps & {
    pageProps: {
        messages: Record<string, string>;
        [key: string]: any;
    };
};

function MyApp({ Component, pageProps }: MyAppProps) {
    return (
        <ErrorBoundary>
            <Head>
                <meta
                    name='viewport'
                    content='width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no'
                />
                <meta name='HandheldFriendly' content='true' />
            </Head>
            <Component {...pageProps} />
        </ErrorBoundary>
    );
}

export default MyApp;
