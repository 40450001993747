import { useRouter } from 'next/router';
import { FC, useEffect, useState } from 'react';

import classNames from '@/helpers/classNames';
import { roboto } from '@/helpers/fonts';
import {
    saveRestoreRequestIDByGameID,
    setStorageValue,
} from '@/helpers/storage';

export const ErrorBox: FC<{ error: string }> = ({ error }) => {
    const { query } = useRouter();

    const hasSessionExpired =
        error &&
        error.includes &&
        error.includes('has been expired or has been used');

    useEffect(() => {
        if (hasSessionExpired) {
            const requestIDs = saveRestoreRequestIDByGameID(
                query.gameId as string,
            );
            setStorageValue('restoreRequestId', requestIDs);
        }
    }, [hasSessionExpired, query.gameId]);

    return (
        <div className='relative flex flex-col items-start justify-center w-[95%] h-full z-100'>
            <div
                role='alert'
                className={classNames('mx-auto z-100', roboto.className)}
            >
                <div className='bg-red-500 text-white font-bold rounded-t px-4 py-2'>
                    Oops, something is wrong
                </div>
                <div className='border border-t-0 border-red-400 rounded-b bg-red-100 px-4 py-3 text-red-700'>
                    <p className='mb-0'>
                        Please try again later or contact support
                    </p>
                    {process.env.NODE_ENV === 'development' && (
                        <button
                            className='mt-4 w-full bg-red-500 hover:bg-red-400 text-white font-bold py-2 px-4 border-b-4 border-red-700 hover:border-red-500 rounded'
                            onClick={() => self.location.reload()}
                        >
                            Reload
                        </button>
                    )}
                </div>
            </div>
        </div>
    );
};

const useErrorBox = () => {
    const { replace, query } = useRouter();
    const [error, setError] = useState('');

    return {
        query,
        error,
        setError,
        replace,
        ErrorBox,
    };
};

export default useErrorBox;
