import { DataObject, HandleSpinProps, UIOptions } from '@/context/types';

import { uuid } from './calc';

const UI_OPTIONS_KEY = process.env.NEXT_PUBLIC_LOCAL_STORAGE as string;

const DEFAULT_UI_OPTIONS: DataObject = {
    sound: true,
    volume: 0.5,
    quickSpin: false,
    autoPlay: false,
    hotkey: false,
};

function getStorageValues<T extends DataObject = UIOptions>(
    storageKey = UI_OPTIONS_KEY,
    defaultValues = DEFAULT_UI_OPTIONS as T,
): T {
    try {
        const values = window.localStorage.getItem(storageKey);
        if (!values) return defaultValues;
        const result = JSON.parse(values);
        if (typeof result.sound !== 'boolean') {
            result.sound = defaultValues.sound;
        }
        return result;
    } catch {
        return defaultValues;
    }
}

function setStorageValue<T extends DataObject = UIOptions>(
    key: keyof T,
    value: any,
    storgeKey = UI_OPTIONS_KEY,
    defaultValues = DEFAULT_UI_OPTIONS as T,
): void {
    try {
        const values = getStorageValues<T>(storgeKey, defaultValues);
        window.localStorage.setItem(
            storgeKey,
            JSON.stringify({ ...values, [key]: value }),
        );
    } catch {
        defaultValues[key] = value;
    }
}

const saveRestoreRequestIDByGameID = (
    gameId: string,
    restoreRequestId?: string | undefined,
) => {
    let requestIDs = getStorageValues().restoreRequestId;
    if (!requestIDs || typeof requestIDs === 'string') {
        requestIDs = {};
    }
    (requestIDs as Record<string, string | undefined>)[gameId] =
        restoreRequestId;
    return requestIDs;
};

const getRestoreRequestIDByGameID = (gameId: string) => {
    let requestIDs = getStorageValues().restoreRequestId;
    if (typeof requestIDs === 'string') {
        requestIDs = {};
    }
    return (requestIDs as Record<string, string | undefined>)[gameId];
};

const getRequestID = (props: HandleSpinProps, gameId: string): string => {
    if (!props?.requestId) {
        return uuid();
    }
    if (typeof props?.requestId !== 'string') {
        return props?.requestId[gameId];
    }
    return props?.requestId;
};

export {
    getRequestID,
    getRestoreRequestIDByGameID,
    getStorageValues,
    saveRestoreRequestIDByGameID,
    setStorageValue,
    UI_OPTIONS_KEY,
};
