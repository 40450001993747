import React from 'react';

import { ErrorBox } from '@/hooks/useErrorBox';

type Props = {
    children: React.ReactNode;
};

type State = {
    hasError: boolean;
    message?: string;
};

class ErrorBoundary extends React.Component {
    constructor(props: Props) {
        super(props);

        // Define a state variable to track whether is an error or not
        this.state = { hasError: false, message: undefined };
    }
    static getDerivedStateFromError(error: string) {
        return { hasError: true, message: error };
    }

    render() {
        if ((this.state as State).hasError) {
            return <ErrorBox error={(this.state as State).message as string} />;
        }
        return (this.props as Props).children;
    }
}

export default ErrorBoundary;
