import Decimal from 'decimal.js';
import { toast } from 'react-toastify';

import { DataObject, DefaultStateType } from '@/context/types';

const toDecimal = (number: number | string, digits = 4) =>
    Number(Number(number).toFixed(digits));

const numberFormat = (number?: number | string, decimal = 2, round = false) => {
    if (typeof number !== 'number') {
        return '';
    }

    const factor = number >= 0 ? 1 : -1;

    if (round && number.toString().includes('.')) {
        number = number.toFixed(decimal);
    }

    number = number.toString();

    let config: Intl.NumberFormatOptions = {};

    if (number.includes('.')) {
        const match = number.match(
            new RegExp('\\d+\\.\\d{' + decimal.toString() + '}'),
        );
        if (Array.isArray(match)) {
            number = Number(match[0]) * factor;
            config = {
                minimumFractionDigits: decimal,
                maximumFractionDigits: decimal,
            };
        }
    }

    return new Intl.NumberFormat('en-US', config).format(Number(number));
};

const numberFormatDecimal = (
    number: Decimal | number | string,
    decimalPlaces = 2,
    round = false,
): string => {
    // Create a Decimal instance from the number
    const decimalNumber = new Decimal(number);

    // Round the number if required
    let formattedNumber: string;
    if (round) {
        formattedNumber = decimalNumber.toFixed(
            decimalPlaces,
            Decimal.ROUND_HALF_UP,
        );
    } else {
        formattedNumber = decimalNumber.toFixed(decimalPlaces);
    }

    // Convert the fixed string back to a number for formatting
    const numberForFormatting = parseFloat(formattedNumber);

    // Format the number using Intl.NumberFormat
    const config: Intl.NumberFormatOptions = {
        minimumFractionDigits: decimalPlaces,
        maximumFractionDigits: decimalPlaces,
    };

    return new Intl.NumberFormat('en-US', config).format(numberForFormatting);
};

const preventInsufficientBalance = (
    state:
        | DefaultStateType
        | { user: DataObject; outcome: DataObject; playerInput: DataObject },
    ignoreZeroBalance = false,
) => {
    let balance = state.user.balance;

    if (ignoreZeroBalance ? balance < 0 : balance <= 0) {
        toast.error('Out of the balance', {
            toastId: 'spinError',
        });
        return true;
    }

    if (state.outcome && state.outcome.betAmount) {
        balance += +state.outcome.betAmount;
    }

    if (state.playerInput.betAmount > balance) {
        toast.error('Not enough credits', {
            toastId: 'spinError',
        });
        return true;
    }
    return false;
};

const randomIntBetween = (min: number, max: number): number =>
    Math.floor(Math.random() * (max - min + 1) + min);

const doubleNumberFormat = (number: number | string): string => {
    let displayNumber = number.toString();
    if (displayNumber.length < 2) {
        displayNumber = '0' + displayNumber;
    }
    return displayNumber;
};

const fixDisplayDecimal = (
    number: number | string = 0,
    decimal: number = 2,
): string => {
    const [whole, fraction] = number.toString().split('.');

    // If no fraction is found, add the required number of zeros
    if (!fraction) {
        return `${whole}.${'0'.repeat(decimal)}`;
    }

    // If the fraction is shorter than the required decimal places, add zeros
    if (fraction.length < decimal) {
        return `${whole}.${fraction}${'0'.repeat(decimal - fraction.length)}`;
    }

    return `${whole}.${fraction.substring(0, decimal)}`;
};

const getDisplayNumber = (number: number): string => {
    return fixDisplayDecimal(numberFormat(number, 2));
};

const counterHelper = () => {
    let i = 0;

    return () => {
        i = i + 1;
        return i;
    };
};

const getFontSize = (
    textLength: number,
    defaultSize: number,
    middleSize: number,
    minimumSize: number,
) => {
    if (textLength > 40 && window.screen.width < 375) {
        return `text-[${minimumSize}px]`;
    } else if (textLength > 40 && window.screen.width < 600) {
        return `text-[${middleSize}px]`;
    } else {
        return `text-[${defaultSize}px]`;
    }
};

const uuid = () => URL.createObjectURL(new Blob([])).slice(-36);

const withFixedDecimalOrNoDecimal = (
    number: string | number,
    decimal: number = 2,
) => {
    const [whole, fraction] = number.toString().split('.');

    if (!fraction) {
        return whole;
    }

    if (fraction.length < decimal) {
        return `${whole}.${fraction}${'0'.repeat(decimal - fraction.length)}`;
    }

    return `${whole}.${fraction.substring(0, decimal)}`;
};

const withChilioi = (hasChilioi: boolean, multiplier: number) => {
    if (!hasChilioi) {
        return withFixedDecimalOrNoDecimal(multiplier);
    }
    let value = multiplier / 1_000;
    if (value < 1_000) {
        return `${value}K`;
    }
    value = value / 1_000;
    return `${value}M`;
};

export {
    counterHelper,
    doubleNumberFormat,
    fixDisplayDecimal,
    getDisplayNumber,
    getFontSize,
    numberFormat,
    numberFormatDecimal,
    preventInsufficientBalance,
    randomIntBetween,
    toDecimal,
    uuid,
    withChilioi,
    withFixedDecimalOrNoDecimal,
};
